
import { defineComponent, ref } from "vue";
// 国际化配置
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default defineComponent({
  setup() {
    const locale = ref(zhCN);
    return { locale };
  },
});
