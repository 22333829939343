
import { defineComponent } from "vue";
import { Login } from "./index.ts";

import { MobileOutlined, LockOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  setup() {
    return { ...Login() };
  },
  components: {
    MobileOutlined,
    LockOutlined,
  },
});
